import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { uniq } from "lodash";
import { useTranslation } from "next-i18next";

import {
  ManagedColumnFilter,
  ManagedMaterialReactTable,
  ValuesTranslator,
  useManagedMaterialReactTable,
} from "@components";
import { UserPermissions } from "@constants";
import { loadTranslations } from "@lib";
import { useApplicationsStore } from "@stores";
import { Row } from "@tanstack/react-table";
import { ApplicationListItem, Job, SourcingCampaign } from "@typings";

import { useHasPermissions, useOTELContext, useOTELSpan, useUserGroup } from "@hooks";
import {
  useFetchCampaignApplicationsList,
  useFetchJobApplicationsList,
  useScoringEnabledQueries,
} from "@hooks/queries";

import { RowActions } from "./cells";
import { INITIAL_LEADS_COLUMNS_VISIBILITY } from "./constants";
import {
  useAdvancedFilters,
  useCampaignQuestions,
  useLeadsCategories,
  useLeadsGroupActions,
  useOnRowClick,
} from "./hooks";
import { useLeadsColumns } from "./leads-columns";

const onBeforeSaveColumnFilters = (value: ManagedColumnFilter[] | undefined, destination: "url" | "localStorage") => {
  if (value == null) {
    return [];
  }

  if (destination === "url") {
    return value.filter((filter) => !filter.id.startsWith("question_"));
  }

  return value.filter((filter) => filter.id !== "status");
};

export type LeadsProps =
  | {
      sourcingCampaign: SourcingCampaign;
      job?: never;
    }
  | {
      sourcingCampaign?: never;
      job: Job;
    };

export const Leads: FC<LeadsProps> = ({ sourcingCampaign, job }) => {
  // ================================================================================
  // Load translations.
  // ================================================================================

  const { t } = useTranslation(["application-status", "application-scoring", "applications-advanced-filters", "leads"]);
  loadTranslations("application-status");
  loadTranslations("application-scoring");
  loadTranslations("applications-advanced-filters");
  loadTranslations("leads");

  // ================================================================================
  // Retrieve information from current user session.
  // ================================================================================

  const organizationName = useUserGroup();
  const canDeleteApplication = useHasPermissions([UserPermissions.ApplicationPermissions.Delete]);

  // ================================================================================
  // Load store information.
  // ================================================================================

  const setCurrentApplicationID = useApplicationsStore((state) => state.setCurrentApplicationID);
  const setApplicationsID = useApplicationsStore((state) => state.setApplicationsID);

  // Reset the current application when the applications source changes.
  useEffect(() => {
    setCurrentApplicationID(null);
    setApplicationsID([]);
  }, [setApplicationsID, setCurrentApplicationID]);

  // ================================================================================
  // Fetch all applications from sources.
  // ================================================================================

  const { ctx, span } = useOTELSpan(useOTELContext(), "leads");

  const { applications: campaignApplications, isLoading: campaignIsLoading } = useFetchCampaignApplicationsList(
    ctx,
    organizationName,
    sourcingCampaign?.campaign_id,
  );
  const { applications: jobApplications, isLoading: jobIsLoading } = useFetchJobApplicationsList(
    ctx,
    organizationName,
    job?.id,
  );
  const applications = useMemo(
    () => (sourcingCampaign ? campaignApplications : jobApplications),
    [campaignApplications, jobApplications, sourcingCampaign],
  );

  const isLoading = campaignIsLoading || jobIsLoading;

  useEffect(() => {
    if (!isLoading) {
      span.end();
    }
  }, [isLoading, span]);

  // ================================================================================
  // Retrieve list of campaign IDs.
  // ================================================================================

  const campaignIDs = useMemo(() => uniq(applications.map((application) => application.campaign_id)), [applications]);

  // ================================================================================
  // Table methods.
  // ================================================================================

  const onRowClick = useOnRowClick({ campaignID: sourcingCampaign?.campaign_id, jobID: job?.id });

  const translateColumn = useCallback(
    (columnID: string) =>
      columnID.startsWith("question_") ? columnID.split("question_")[1] : t(columnID, { ns: "leads" }),
    [t],
  );

  const translateColumnValues = useMemo<ValuesTranslator>(
    () => new Map([["compatibility", (value: string) => t(value, { ns: "application-scoring" })]]),
    [t],
  );

  const columns = useLeadsColumns(campaignIDs, applications);

  const [filterableColumns, setFilterableColumns] = useState<string[]>(["job_title", "job_location"]);

  // ================================================================================
  // Create table.
  // ================================================================================

  const table = useManagedMaterialReactTable<ApplicationListItem>({
    seizaFilters: {
      cacheKey: sourcingCampaign ? `leads_campaign_${sourcingCampaign.campaign_id}` : `leads_job_${job.id}`,
      initialColumnsVisibility: INITIAL_LEADS_COLUMNS_VISIBILITY,
      onBeforeSaveColumnFilters,
    },
    columns,
    data: applications,
    state: { isLoading },
    initialState: {
      pagination: { pageSize: 50, pageIndex: 0 },
      columnPinning: { right: ["mrt-row-actions"] },
    },
    defaultColumn: {
      muiTableBodyCellProps: ({ cell, row, table }) => ({
        onClick: () => {
          if (cell.id !== "mrt-row-select" && !cell.id.includes("mrt-row-actions")) onRowClick(table, row.original.id);
        },
      }),
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    enableColumnFilters: false,
    enableGlobalFilter: true,
    enableHiding: false,
    enableRowActions: true,
    enableTopToolbar: false,
    enableFacetedValues: true,
    muiTableContainerProps: { id: "application_list" },
    muiTableBodyRowProps: ({ row }) => ({
      id: `application_item_${row.original.id}`,
      className: "application_item",
    }),
    filterFns: {
      statusArrayIncludes: (row: Row<ApplicationListItem>, columnId: string, filterValue: string[] | null) =>
        filterValue === null ||
        filterValue?.length === 0 ||
        filterValue?.includes(row.getValue(columnId)) ||
        filterValue?.includes("all"),
    },
    renderRowActions: ({ table, row }) => (
      <RowActions
        row={row}
        canDeleteApplication={canDeleteApplication}
        disabled={table.getSelectedRowModel().rows.length > 0}
      />
    ),
  });

  const { setColumnOrder, setColumnVisibility } = table;

  // ================================================================================
  // Fetch scoring information.
  // ================================================================================

  const scoringEngineStatus = useScoringEnabledQueries(...campaignIDs);

  // Update filters if scoring is enabled.
  useEffect(() => {
    if (Object.values(scoringEngineStatus).some((scoringEngineEnabled) => scoringEngineEnabled)) {
      setFilterableColumns((prevState) => {
        // Don't update state if the compatibility column is already included.
        if (prevState.includes("compatibility")) return prevState;
        return [...prevState, "compatibility"];
      });
      setColumnVisibility((prevState) => {
        // Don't update state if the compatibility column is already included.
        if (prevState?.compatibility != null) return prevState;
        return { ...prevState, compatibility: true };
      });
    }
  }, [scoringEngineStatus, setColumnVisibility]);

  // ================================================================================
  // Fetch question information.
  // ================================================================================

  const campaignQuestions = useCampaignQuestions(campaignIDs, applications);

  // Update filters when new questions are available.
  useEffect(() => {
    const questionColumns = campaignQuestions.map((question) => `question_${question.label}`);
    if (questionColumns.length) {
      setFilterableColumns((prevState) => [
        ...prevState,
        // Prevent duplicates when multiple campaigns have questions with the same labels.
        ...questionColumns.filter((column) => !prevState.includes(column)),
      ]);
      setColumnVisibility((prevState) => {
        const newColumns: string[] = questionColumns.filter((column) => prevState[column] == null);
        // No columns were added. Don't trigger React state update.
        if (newColumns.length === 0) return prevState;
        return { ...prevState, ...Object.fromEntries(newColumns.map((column) => [column, false])) };
      });
    }
  }, [campaignQuestions, setColumnVisibility]);

  // ================================================================================
  // Table component configuration.
  // ================================================================================

  const { categories, onCategoryChange } = useLeadsCategories(applications, table);

  const { groupActions, groupActionsModals } = useLeadsGroupActions(
    {
      campaignID: sourcingCampaign?.campaign_id,
      jobID: job?.id,
    },
    table,
  );

  const { advancedFilters } = useAdvancedFilters(table);

  // Ensure that the columns are in the correct order.
  useEffect(() => {
    setColumnOrder(["mrt-row-select", ...columns.map((column) => column.id!), "mrt-row-actions"]);
  }, [columns, setColumnOrder]);

  return (
    <>
      <ManagedMaterialReactTable
        table={table}
        title={sourcingCampaign?.name ?? job?.title ?? ""}
        categories={categories}
        onCategoryChange={onCategoryChange}
        groupedActions={groupActions}
        filterableColumns={filterableColumns}
        advancedFilters={advancedFilters}
        translateColumn={translateColumn}
        translateColumnValues={translateColumnValues}
        globalFilter
        showActiveFilters
      />

      {groupActionsModals}
    </>
  );
};
