import { useCallback, useMemo } from "react";

import { AxiosError } from "axios";

import { OrganizationsAPI } from "@api";
import { QUERY_KEYS } from "@constants";
import { context } from "@opentelemetry/api";
import { MutateOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Feature, ToggleFeatureParams } from "@typings";

export interface OrganizationFeatures {
  features: Feature[] | undefined;
  isPendingFeatures: boolean;
  hasFeature: (featureName: string) => boolean;
  toggleFeature: (
    featureName: string,
    enabled: boolean,
    options?: MutateOptions<void, unknown, ToggleFeatureParams, unknown>,
  ) => void;
  isTogglingLoading: boolean;
}

export const useOrganizationFeatures = (
  groupID: string,
  options?: Omit<UseQueryOptions<Feature[], AxiosError>, "queryKey" | "queryFn">,
): OrganizationFeatures => {
  const queryClient = useQueryClient();

  const { data: features, isPending: isPendingFeatures } = useQuery({
    queryKey: [QUERY_KEYS.ORGANIZATIONS_FEATURES, groupID],
    queryFn: () => OrganizationsAPI.listFeatures(context.active(), groupID),
    ...(options ?? {}),
    enabled: !!groupID && (options?.enabled ?? true),
  });

  const featuresObject = useMemo(() => {
    return features?.reduce((acc, feature) => ({ ...acc, [feature.name]: true }), {});
  }, [features]);

  const hasFeature = useCallback(
    (featureName: string): boolean => featuresObject?.[featureName] ?? false,
    [featuresObject],
  );

  const { mutate: enable, isPending: isEnablingLoading } = useMutation({
    mutationFn: (data: ToggleFeatureParams) => OrganizationsAPI.enableFeature(context.active(), data),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.ORGANIZATIONS_FEATURES, groupID],
        })
        .catch(() => {});
    },
  });

  const { mutate: disable, isPending: isDisablingLoading } = useMutation({
    mutationFn: (data: ToggleFeatureParams) => OrganizationsAPI.disableFeature(context.active(), data),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.ORGANIZATIONS_FEATURES, groupID],
        })
        .catch(() => {});
    },
  });

  const toggleFeature = useCallback(
    (featureName: string, enabled: boolean, options?: MutateOptions<void, unknown, ToggleFeatureParams>): void => {
      if (enabled) {
        return enable({ groupID, name: featureName }, options);
      } else {
        return disable({ groupID, name: featureName }, options);
      }
    },
    [disable, enable, groupID],
  );

  return {
    features,
    isPendingFeatures,
    hasFeature,
    toggleFeature,
    isTogglingLoading: isEnablingLoading || isDisablingLoading,
  };
};
